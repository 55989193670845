<template>
  <img alt="Vue logo" src="./assets/logo.png">
  <div class="container">
    <router-view/>
  </div>
</template>

<script>


export default {
  name: 'App'
}
</script>

<style>
@import "assets/style.css";
</style>
